<template id="player_evaluation">
  <b-container fluid>
    <b-navbar type="dark" class="p-0" v-if="osservato">
      <b-navbar-nav :class="!auth.isAdmin() ? 'no-admin' : ''">
        <b-nav-item
          v-if="osservato.giocatore.opta_player && auth.isUserEnable('opta')"
          :to="'/scouting/view/player/' + osservato.giocatore.opta_player.id"
        >
          Player card
        </b-nav-item>
        <b-nav-item
          v-if="auth.isUserEnable('player_edit')"
          :to="'/scouting/edit/player/' + osservato.giocatore.id"
        >
          Data edit
        </b-nav-item>
        <b-nav-item
          v-if="
            osservato.giocatore.wyscout_id &&
            auth.isUserEnable('player_videotag')
          "
          :to="'/scouting/videotag/player/' + osservato.giocatore.id"
        >
          Videotag
        </b-nav-item>
        <b-nav-item
          v-if="osservato.giocatore.opta_player && auth.isUserEnable('opta')"
          :to="
            '/scouting/player/index/games/' + osservato.giocatore.opta_player.id
          "
        >
          Matches
        </b-nav-item>
      </b-navbar-nav>
    </b-navbar>
    <b-card v-if="osservato">
      <b-row align-v="center">
        <b-col cols="1">
          <avatar :avatar="osservato.giocatore.avatar_url" />
        </b-col>
        <b-col>
          <h5>
            {{ osservato.giocatore.cognome }} {{ osservato.giocatore.nome }}
          </h5>
          <p class="text-muted">
            {{
              osservato.giocatore.ruolo_ideale &&
              osservato.giocatore.ruolo_ideale.length > 0
                ? osservato.giocatore.ruolo_ideale[0].ruolo
                : ""
            }}
          </p>
        </b-col>
        <b-col>
          <h5>Evaluations: {{ totalRows }}</h5>
        </b-col>
        <b-col
          class="m-0 p-0 pb-2"
          cols="6"
          v-show="!osservato.giocatore.opta_player && auth.isUserEnable('opta')"
        >
          <b-card no-body v-if="osservato">
            <div v-show="playlist.length > 0">
              <b-img
                v-if="showImageEditor"
                style="position: absolute; z-index: 2"
                :src="imageEditor"
                :width="widthEditor"
                :height="heightEditor"
              />
              <video
                id="video-wrapper"
                class="video-js vjs-default-skin vjs-16-9"
                controls
                data-setup="{}"
              ></video>
              <b-pagination
                v-model="page"
                class="mt-2 mb-0"
                :total-rows="playlist.length"
                :per-page="1"
                @change="selectClip"
                size="sm"
                align="center"
                :limit="20"
              ></b-pagination>
            </div>
            <div
              v-show="playlist.length == 0"
              style="text-align: center; margin-top: 100px"
            >
              <h2>No video available</h2>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </b-card>
    <b-row class="my-2" align-v="center">
      <b-col md="4">
        <b-button variant="primary" @click="nuovaValutazione"
          >New Evaluation</b-button
        >
        <b-button
          v-if="auth.isAdmin() || user.id == 13"
          class="ml-3"
          variant="warning"
          @click="downloadCsv"
          >Download CSV</b-button
        >
      </b-col>
    </b-row>
    <b-table
      striped
      hover
      show-empty
      ref="evalTable"
      stacked="md"
      :items="valutazioni"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
    >
      <template v-slot:cell(gradimento)="data">
        <div
          v-if="auth.isUserEnable('opta')"
          class="py-1"
          :title="data.value.nome"
          :style="'background-color:' + data.value.colore"
        >
          <grad1 class="grad" v-if="data.value.id == 1" />
          <grad2 class="grad" v-if="data.value.id == 2" />
          <grad3 class="grad" v-if="data.value.id == 3" />
          <grad4 class="grad" v-if="data.value.id == 4" />
          <grad5 class="grad" v-if="data.value.id == 5" />
        </div>
        <div v-else>
          <div style="font-size: 0.7rem">
            {{ data.value.id ? labelGrad[data.value.id - 1] : "" }}
          </div>
          <div>{{ data.value.id ? siglaGrad[data.value.id - 1] : "" }}</div>
        </div>
      </template>
      <template v-slot:cell(created_at)="data">{{
        data.value | formatDate
      }}</template>
      <template v-slot:cell(utente)="data">{{ data.value.nickname }}</template>
      <template v-slot:cell(partite)="data">
        <div v-for="item in data.value" :key="item.id">
          {{ getLabelPartita(item.partita) }},
          {{ item.partita.data | formatDateHour }}
        </div>
      </template>
      <template v-slot:cell(azioni)="row">
        <b-button
          size="sm"
          @click.stop="editValutazione(row.item, row.index, $event.target)"
          class="mr-1"
        >
          Edit <b-icon icon="pencil"></b-icon>
        </b-button>
        <b-button
          size="sm"
          @click.stop="eliminaValutazione(row.item.id)"
          class="mr-1"
          variant="danger"
        >
          Del <b-icon icon="trash"></b-icon>
        </b-button>
      </template>
    </b-table>

    <b-modal
      id="evalModal"
      ref="evalModal"
      :title="form.id ? 'Edit evaluation' : 'New evaluation'"
      ok-only
      ok-title="Close"
    >
      <b-row class="mb-2">
        <b-col cols="12" class="my-2">
          <p>Insert evaluation:</p>
          <b-form-textarea
            id="textarea"
            v-model="form.note"
            rows="4"
            max-rows="6"
          ></b-form-textarea>
        </b-col>
        <b-col cols="12" class="my-2">
          <p>Select a liking:</p>
          <b-form-radio-group
            buttons
            v-model="form.gradimento"
            button-variant="outline-primary"
          >
            <b-form-radio :value="null"
              ><b-icon icon="blank"></b-icon
            ></b-form-radio>
            <b-form-radio
              @click.native="selectGradimento(grad.id)"
              v-for="grad in gradimenti"
              :value="grad.id"
              :style="
                auth.isUserEnable('opta')
                  ? 'background-color:' +
                    (grad.id == form.gradimento
                      ? grad.colore
                      : grad.colore + '7F')
                  : ''
              "
              :key="grad.id"
            >
              <div v-if="auth.isUserEnable('opta')" :title="grad.nome">
                <grad1 class="grad" v-if="grad.id == 1" />
                <grad2 class="grad" v-if="grad.id == 2" />
                <grad3 class="grad" v-if="grad.id == 3" />
                <grad4 class="grad" v-if="grad.id == 4" />
                <grad5 class="grad" v-if="grad.id == 5" />
              </div>
              <div v-else style="width: 40px">
                <div style="font-size: 0.7rem">
                  {{ labelGrad[grad.id - 1] }}
                </div>
                <div>{{ siglaGrad[grad.id - 1] }}</div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
        </b-col>
        <b-col cols="12" class="my-2"
          ><p>Select one or more games to associate the evaluation:</p></b-col
        >
        <b-col cols="12">
          <div v-if="is_search">
            <h4>
              Waiting...
              <img :src="loadingImg" style="width: 150px" />
            </h4>
          </div>
          <p v-if="!is_search && !matches.length">No match found!</p>
          <div v-if="!is_search && matches.length">
            <p>{{ matches.length }} matches found!</p>
          </div>
          <b-row style="max-height: 150px; overflow: auto">
            <b-col cols="12" v-for="match in matches" :key="match.id">
              <b-form-checkbox v-model="form.partite" :value="match.matchId">
                {{ match.label }} - {{ match.date | formatDateHour }}
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="2">
          <b-button variant="primary" @click.prevent="salvaValutazione"
            >Save</b-button
          >
        </b-col>
        <b-col>
          <div v-if="saving">
            Saving in progress...<img :src="loadingImg" style="width: 40px" />
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </b-container>
</template>

<script>
import moment from "moment";
import videojs from "video.js";
import videojsPlaylist from "videojs-playlist";
videojs.registerPlugin("playlist", videojsPlaylist);
import Auth from "@/lib/auth.js";
import Avatar from "@/components/Avatar.vue";
import loadingImg from "@/assets/images/loading.gif";
import grad1 from "@/assets/images/grad1.svg";
import grad2 from "@/assets/images/grad2.svg";
import grad3 from "@/assets/images/grad3.svg";
import grad4 from "@/assets/images/grad4.svg";
import grad5 from "@/assets/images/grad5.svg";
export default {
  components: {
    Avatar,
    grad1,
    grad2,
    grad3,
    grad4,
    grad5,
  },

  data: function () {
    return {
      osservato: null,
      matches: [],
      gradimenti: [],
      is_search: false,
      form: {
        id: null,
        note: null,
        gradimento: null,
        partite: [],
        osservato: null,
      },
      currentPage: 1,
      perPage: 15,
      totalRows: 0,
      saving: false,
      loadingImg,
      labelGrad: ["WIN", "QUALIFY", "GO", "STAY", "WIN"],
      siglaGrad: ["TOP", "CL", "EL", "A", "B"],
      riassunti: [],
      videotags: [],
      playlist: [],
      currentTag: null,
      editorShown: null,
      imageEditor: null,
      width: 700,
      height: 420,
      page: 1,
    };
  },

  created: function () {
    this.$http.get("/gradimenti").then((response) => {
      this.gradimenti = response.data;
    });

    this.$http
      .get("/scouting/view/giocatore/" + this.$route.params.id)
      .then((response) => {
        this.osservato = response.data;
        if (!this.osservato.id) {
          this.$store.commit("msgPush", {
            msg: "Player not found!",
            status: 0,
          });
          this.$router.push({ name: "ScoutingPlayers" });
        } else {
          if (
            !this.osservato.giocatore.opta_player &&
            this.auth.isUserEnable("opta")
          ) {
            this.getRiassunti(this.osservato.giocatore.id);
          }
        }
      });
  },

  computed: {
    auth: function () {
      return Auth;
    },
    user: function () {
      return Auth.user;
    },
    fields: function () {
      var fields = [];
      fields.push({ key: "created_at", label: "Date", class: "text-center" });
      if (
        this.auth.isAdmin() ||
        this.auth.isOsservCapo() ||
        this.user.id == 13
      ) {
        fields.push({
          key: "utente",
          label: "User",
          thStyle: { "min-width": "100px" },
        });
      }
      fields.push({ key: "gradimento", label: "Liking", class: "text-center" });
      fields.push({
        key: "partite",
        label: "Matches",
        thStyle: { "min-width": "150px" },
      });
      fields.push({ key: "note", label: "Text" });
      if (this.auth.isAdmin()) {
        fields.push({
          key: "azioni",
          label: "Actions",
          thStyle: { "min-width": "180px" },
        });
      }
      return fields;
    },
    showImageEditor() {
      if (this.imageEditor) {
        return true;
      }
      return false;
    },
    widthEditor() {
      return this.width;
    },

    heightEditor() {
      return this.height;
    },
  },

  methods: {
    nuovaValutazione: function () {
      this.form.id = null;
      this.form.osservato = this.osservato.id;
      this.form.note = null;
      this.form.gradimento = null;
      this.form.partite = [];
      if (this.matches.length == 0 && this.osservato.giocatore.wyscout_id) {
        this.searchPartita();
      }
      this.$refs.evalModal.show();
    },
    editValutazione: function (valutazione) {
      this.form.id = valutazione.id;
      this.form.osservato = this.osservato.id;
      this.form.note = valutazione.note;
      this.form.gradimento = valutazione.gradimento_id;
      this.form.partite = [];
      for (var i in valutazione.partite) {
        this.form.partite.push(valutazione.partite[i].partita.wyscout_id);
      }
      if (this.matches.length == 0 && this.osservato.giocatore.wyscout_id) {
        this.searchPartita();
      }
      this.$refs.evalModal.show();
    },
    salvaValutazione: function () {
      this.saving = true;
      this.$http
        .post("/scouting/salva/valutazione", this.form)
        .then((response) => {
          this.saving = false;
          this.$store.commit("msgPush", {
            msg: response.data.message,
            status: 1,
          });
          this.$refs.evalTable.refresh();
          this.$refs.evalModal.hide();
        });
    },
    eliminaValutazione(id) {
      if (confirm("Are you sure to delete the evaluation ?")) {
        this.$http
          .get("/scouting/elimina/valutazione/" + id)
          .then((response) => {
            this.$store.commit("msgPush", {
              msg: response.data.message,
              status: 1,
            });
            this.$refs.evalTable.refresh();
          });
      }
    },
    searchPartita: function () {
      var wsId = this.osservato.giocatore.wyscout_id;
      if (wsId != "" && wsId != 0 && wsId != null) {
        this.matches = [];
        this.is_search = true;
        this.$http.get("/ws/get/matches/by/player/" + wsId).then((response) => {
          this.matches = response.data;
          this.is_search = false;
        });
      } else {
        alert("No game available!");
      }
    },
    valutazioni(ctx) {
      return this.$http
        .get("/scouting/valutazioni/" + this.$route.params.id, {
          params: {
            page: ctx.currentPage,
            per_page: ctx.perPage || 15,
          },
        })
        .then((res) => {
          if (!res.data) return [];
          const data = res.data.data || [];
          this.totalRows = res.data.total;
          this.currentPage = res.data.current_page;
          return data;
        })
        .catch(() => []);
    },
    selectGradimento(id) {
      this.form.gradimento = id;
    },
    getLabelPartita(partita) {
      var label = "";
      var squadre = partita.squadre;
      if (squadre[0].pivot.side == "home") {
        label = squadre[0].officialName + " - " + squadre[1].officialName;
      } else {
        label = squadre[1].officialName + " - " + squadre[0].officialName;
      }
      return label;
    },
    getRiassunti(id) {
      this.$http.get("/videotag/riassunto/giocatore/" + id).then((response) => {
        this.riassunti = response.data.riassunti;

        this.videotags = [];
        this.playlist = [];
        for (var i in this.riassunti) {
          var videotags = this.riassunti[i].videotags;
          this.videotags = this.videotags.concat(videotags);
          for (var i in videotags) {
            var videotag = videotags[i];
            var source = {
              sources: [
                {
                  src:
                    this.$store.state.apiEndPoint +
                    "/videotag/play/clip/" +
                    videotag.id +
                    "/" +
                    Auth.user.id +
                    "/" +
                    Auth.user.api_token,
                  type: "video/mp4",
                },
              ],
            };
            this.playlist.push(source);
          }
        }

        if (!this.$video) {
          this.$video = videojs("video-wrapper");
          this.$video.on("timeupdate", this.onTimeUpdate);
          this.$video.on("playlistitem", this.autoSelectTag);
          this.$video.playlist(this.playlist);
          this.$video.playlist.autoadvance(0);
        }
      });
    },
    selectClip(page) {
      this.editorShown = null;
      this.currentTag = this.videotags[page - 1];
      this.$video.playlist.currentItem(page - 1);
    },
    onTimeUpdate() {
      if (this.currentTag && this.currentTag.editors.length > 0) {
        for (var i in this.currentTag.editors) {
          var editor = this.currentTag.editors[i];
          var time =
            (moment(editor.time, "HH:mm:ss") -
              moment(this.currentTag.inizio, "HH:mm:ss")) /
            1000;

          if (
            editor.download &&
            this.formatDuration(time) ==
              this.formatDuration(this.$video.currentTime()) &&
            editor.id != this.editorShown
          ) {
            if (!this.$video.paused()) {
              this.editorShown = editor.id;
              this.$video.pause();
              this.width = this.$video.currentWidth();
              this.height = this.$video.currentHeight();
              this.imageEditor = editor.foreground_url;
              const seconds = editor.seconds;
              setTimeout(() => {
                this.toggleEditor();
              }, seconds * 1000);
            }
          }
        }
      }
    },
    toggleEditor() {
      this.imageEditor = null;
      this.$video.play();
    },
    autoSelectTag() {
      this.currentTag = this.videotags[this.$video.playlist.currentIndex()];
      this.page = this.$video.playlist.currentIndex() + 1;
    },
    downloadCsv() {
      window.location =
        this.$store.state.apiEndPoint +
        "/scouting/download/csv/valutazioni/" +
        this.$route.params.id +
        "/" +
        this.user.id +
        "/" +
        this.user.api_token;
    },
  },
  beforeDestroy() {
    if (this.$video) {
      this.$video.off("timeupdate", this.onTimeUpdate);
      this.$video.dispose();
      this.$video = null;
    }
  },
  filters: {
    formatDate: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY");
      }
      return "";
    },
    formatDateHour: function (date) {
      var m = moment(date);
      if (m.isValid()) {
        return m.format("DD/MM/YYYY HH:mm");
      }
      return "";
    },
  },
};
</script>
<style lang="scss">
.video-js {
  width: 100%;
  height: 100%;
  flex-grow: 1;
}

.vjs-default-skin .vjs-big-play-button {
  $big-play-width: 3em;
  $big-play-height: 1.5em;
  left: 50%;
  top: 50%;
  margin-left: -($big-play-width / 2);
  margin-top: -($big-play-height / 2);
}
</style>
